import React from "react"
import Layout from "../../components/UI/layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Trading = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          frontmatter: { published: { eq: true } }
          slug: { regex: "/research/trading/" }
        }
      ) {
        nodes {
          frontmatter {
            title
            author
            publish_date(formatString: "MMM DD, YYYY")
            last_updated(formatString: "MMM DD, YYYY")
          }
          timeToRead
          excerpt
          slug
        }
      }
    }
  `)
  console.log(data)
  const posts = data.allMdx.nodes.map(post => {
    console.log(post)
    return {
      title: post.frontmatter.title,
      href: post.slug,
      description: post.excerpt,
      date: post.frontmatter.publish_date,
      datetime: post.frontmatter.publish_date,
      imageUrl:
        "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
      readingTime: `${post.timeToRead} min`,
      author: {
        name: post.frontmatter.author,
        href: "/team/about",
        imageUrl: "../../../content/assets/images/cs_profile_thumbnail",
      },
    }
  })

  return (
    <Layout>
      <div className="relative pb-20 pt-8 px-4 sm:px-6 lg:pb-28">
        <div className="absolute inset-0">
          <div className="h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
              Trading articles
            </h2>
            <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-4">
              This is a collection and descripton of all the work I've done on
              researching various algo trading strategies and evaluating trading
              signals. It also includes how-tos and tutorials on various topics.
            </p>
          </div>
          <div className="mt-10 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {posts.map(post => (
              <div
                key={post.title}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={post.imageUrl}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-gray-800 p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <Link to={`/${post.href}`} className="block mt-2">
                      <p className="text-xl font-semibold text-white">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-white">
                        {post.description}
                      </p>
                    </Link>
                  </div>
                  <div className="mt-4 flex items-center">
                    <div className="flex-shrink-0">
                      <a href={post.author.href}>
                        <span className="sr-only">{post.author.name}</span>
                        <StaticImage
                          className="h-10 w-10 rounded-full"
                          src={
                            "../../../content/assets/images/cs_profile_thumbnail.jpg"
                          }
                          alt="Profile picture"
                        />
                      </a>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-cyan-200 mb-1">
                        <a href={post.author.href} className="hover:underline">
                          {post.author.name}
                        </a>
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-300">
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.readingTime}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Trading
